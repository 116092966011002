import { FEATURE } from 'api/src/common/enums'

import { routes, navigate } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import OnboardingHomeLinks from 'src/components/Onboarding/OnboardingHomeLinks'
import OnboardingPageHeader from 'src/components/Onboarding/OnboardingPageHeader'
import useFeature from 'src/lib/hooks/useFeature'
const OnboardingHomeLinksPage = () => {
  // Get Feature Flag Status
  const onboardingFeatureFlag = useFeature(FEATURE.ONBOARDING)

  // Navigate away when permission denied
  if (!onboardingFeatureFlag) {
    navigate(routes.home())
  }

  return (
    <>
      <Metadata
        title="Setup - Home Links"
        description="Setup - Home Links page"
      />
      <div className="relative">
        <OnboardingPageHeader
          currentPage={{
            name: 'Home Links',
            url: routes.onboardingHomeLinks(),
          }}
        />
        <OnboardingHomeLinks />
      </div>
    </>
  )
}

export default OnboardingHomeLinksPage
