import { FC } from 'react'

import { Divider } from '@mui/material'

import { routes } from '@redwoodjs/router'

import SettingsLayoutSectionCell from '../SettingsLayoutSection/SettingsLayoutSectionCell'

import OnboardingSubNavigation, {
  ButtonOption,
} from './OnboardingSubNavigation'

const OnboardingHomeLinks: FC = () => {
  const backButton: ButtonOption = {
    label: 'Groups',
    url: routes.onboardingMembershipGroups(),
  }

  const nextButton: ButtonOption = {
    label: 'Integrations',
    url: routes.onboardingIntegrations(),
  }

  return (
    <div className="max-w-6xl py-24 p-4 mx-auto">
      <div className="flex flex-col gap-8">
        <OnboardingSubNavigation
          title="Home Links"
          backButton={backButton}
          nextButton={nextButton}
        />
        <div className="bg-white px-2 border rounded-xl pt-4 ">
          <div className="px-10 mt-14 w-full">
            <div className="flex items-center justify-between gap-4 flex-wrap">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Home Links
                </h3>
                <p className="max-w-2xl text-sm text-gray-500">
                  Start adding Home Links for quick access to your Tech Stack.
                </p>
              </div>
            </div>
            <Divider className="mt-6" />
          </div>
          <div className=" block items-center p-4">
            <div className="min-h-[500px] w-full flex ">
              <SettingsLayoutSectionCell onboardingMode />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OnboardingHomeLinks
